<template>
  <div class="main">
    <TopPanel />
    <div class="main__content">
      <ToggleSelect v-model="appmode" :values="appmodes" />
      <RangeInput label="Похожесть" v-if="appmode == 'Запросы'" v-model="similarity" />
      <div class="main__textareas" v-if="appmode == 'Дополняющие фразы'">
        <div>
          <label class="main__textareas-label">Содержит</label>
          <textarea class="main__textareas-area" v-model="includes" />
        </div>
        <div>
          <label class="main__textareas-label">Не содержит</label>
          <textarea class="main__textareas-area" v-model="excludes" />
        </div>
      </div>
      <div>
        <textarea v-model="words"></textarea>
        <Btn @click="makeRequest">Сделать запрос</Btn>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import TopPanel from '../components/TopPanel.vue'
import Btn from '../components/buttons/Btn.vue'
import ToggleSelect from '../components/ToggleSelect.vue';
import RangeInput from '../components/RangeInput.vue';

export default {
  name: 'Main',
  data: () => ({
    words: '',
    includes: '',
    excludes: '',
    similarity: 30,
    appmode: 'Запросы',
    appmodes: ['Запросы', 'Страницы', 'Дополняющие фразы']
  }),
  methods: {
    async makeRequest() {
      this.$store.commit('toggleLoad', 'makeRequest')
      let filename
      switch (this.appmode) {
        case 'Запросы':
          await fetch(this.url + `request`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ words: this.words.split('\n').map(el => el.trim()).filter(el => el), modes: this.mode, similarity: this.similarity })
          })
            .then(async res => {
              if (!res.ok) throw (await res.text())
              const header = res.headers?.get('Content-Disposition')
              const parts = header?.split(';')
              if (parts)
                filename = parts[1]?.split('=')[1]?.replaceAll("\"", "") || 'data.txt'
              else
                filename = 'result.txt'
              return res.blob()
            }).then(blob => {
              if (blob != null) {
                var url = window.URL.createObjectURL(blob)
                var a = document.createElement('a')
                a.href = url
                a.download = filename
                document.body.appendChild(a)
                a.click()
                a.remove()
              }
            })
            .catch(err => { console.error(err); alert(err) })
          break;
        case 'Страницы':
          await fetch(this.url + `urlrequest`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ urls: this.words.split('\n').map(el => el.trim()).filter(el => el), modes: this.mode })
          })
            .then(async res => {
              if (!res.ok) throw (await res.text())
              //const header = res.headers.get('Content-Disposition')
              //const parts = header.split(';')
              //filename = parts[1].split('=')[1].replaceAll("\"", "")
              return res.blob()
            }).then(blob => {
              if (blob != null && blob.size > 0) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'data.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              } else {
                alert('Ошибка: blob is empty')
              }
            })
            .catch(err => { console.error(err); alert(err) })
          break;
        case 'Дополняющие фразы':
          await fetch(this.url + `similarkeys`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ phrases: this.words.split('\n').map(el => el.trim()).filter(el => el), modes: this.mode, includes: this.includesArray, excludes: this.excludesArray })
          })
            .then(async res => {
              if (!res.ok) throw (await res.text())
              //const header = res.headers.get('Content-Disposition')
              //const parts = header.split(';')
              //filename = parts[1].split('=')[1].replaceAll("\"", "")
              const comment = decodeURIComponent(res.headers.get('X-Comment'));
              console.error(comment)
              if (comment)
                setTimeout(()=>{
                  alert(comment)
                }, 200)
              return res.blob()
            }).then(blob => {
              if (blob != null && blob.size > 0) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'data.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              } else {
                alert('Ошибка: blob is empty')
              }
            })
            .catch(err => { console.error(err); alert(err) })
          break;
      }

      this.$store.commit('toggleLoad', 'makeRequest')
    }
  },
  components: { TopPanel, Btn, ToggleSelect, RangeInput },
  computed: {
    ...mapState(['Role', 'url', 'mode']),
    includesArray() {
      return this.includes.split('\n').map(el => el.trim()).filter(el => el)
    },
    excludesArray() {
      return this.excludes.split('\n').map(el => el.trim()).filter(el => el)
    }
  },
}
</script>
<style lang="sass">
.main
  & &__textareas
    display: flex
    align-items: stretch
    gap: 20px
    &-label
      display: block
      margin-bottom: 10px
    &-area
      width: 300px
      height: 150px
      resize: vertical

  input, textarea, select
    outline: none
    &:focus-visible
      border-color: black

  padding: 5px
  position: relative
  min-height: 100vh
  background: url('~@/assets/bg.webp')
  background-repeat: repeat
  &::before
    content: ''
    position: absolute
    left:  0
    top: 0
    width: 100%
    height: 100%
    background: rgba(255,255,255,.5)   
    backdrop-filter: blur(2px)
  *
    position: relative
  &__content
    margin-top: 20px
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 20px
    textarea
      width: 400px
      height: 600px
      border-radius: 10px
      padding: 10px
      margin-bottom: 15px

</style>